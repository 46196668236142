import React from "react";

export default function(props) {
  return (
    <div>
      <div className="row no-gutters mb-4 p-3" style={{ marginTop: "12vh" }}>
        <div className="col-md-6 m-auto">
          <div className="row no-gutters">
            <div className="col-md-12">
              <h1 className="light text-white display-4 mb-3">Biologics</h1>
              <h4 className="light text-white more-lh">
                Search FDA-approved biological therapies, including biosimilar
                and interchangeable products
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="row no-gutters">
        <div className="col-md-6 m-auto">
          <div className="row no-gutters">
            <div className="col-lg-6 p-2">
              <div
                className="bq-white-btn m-auto"
                onClick={() => {
                  document.getElementById("query").focus();
                  props.searchView();
                }}
              >
                Search Biologics
              </div>
            </div>
            <div className="col-lg-6 p-2">
              <div
                className="bq-purple-btn m-auto"
                onClick={() => {
                  //   setStyle("style2");
                }}
              >
                Learn More
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row no-gutters p-3 mb-5">
        <div className="col-md-6 m-auto">
          <div className="row no-gutters">
            <div className="col-md-12">
              <h6 className="light text-white more-lh mb-5">
                Data from FDA Purple Book Database
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div className="row no-gutters p-3 mt-5">
        <div className="col-md-6 m-auto">
          <div className="row no-gutters mt-5">
            <div className="col-md-12 mt-5">
              <h6 className="light text-white more-lh mt-4">
                BioQuiv | Copyright &copy; {new Date().getFullYear()}{" "}
                <a
                  className="plain-link"
                  href="https://cosmashealth.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cosmas Health LLC
                </a>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
