import React, { useState, useEffect } from "react";
import "./App.css";
import whiteLogo from "./img/logos/logo-white.svg";
import gradLogo from "./img/logos/logo-grad.svg";
import Landing from "./components/Landing";
import View from "./components/View";

function App() {
  const [view, setView] = useState("view1");
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (window.screen.width > 700) {
      document.getElementById("query").focus();
    }
  }, []);

  useEffect(() => {
    if (view === "view2") {
      document.getElementById("top-hr").classList.remove("hide");
      document.getElementById("app").classList.add("white-background");
      document.getElementById("query").classList.add("gray-query-box");
      document.getElementById("query").classList.remove("white-query-box");
    }
    if (view === "view1") {
      document.getElementById("top-hr").classList.add("hide");
      document.getElementById("app").classList.remove("white-background");
      document.getElementById("query").classList.remove("gray-query-box");
      document.getElementById("query").classList.add("white-query-box");
    }
    console.log(query);
  }, [query, view]);

  function searchView() {
    if (view === "view1") {
      setView("view2");
    }
  }
  function homeView() {
    if (view === "view2") {
      setView("view1");
      document.getElementById("query").value = "";
    }
  }

  return (
    <div className="App" id="app">
      <div className="container p-0">
        <div className="row no-gutters">
          <div className="col-md-12 text-left m-auto" id="query-box">
            <img
              src={view === "view1" ? whiteLogo : gradLogo}
              alt="logo"
              id="bq-main-logo"
              className="opa-hover"
              onClick={homeView}
            />
            <input
              type="text"
              id="query"
              name="query"
              className="white-query-box"
              placeholder="search biologics"
              autoComplete="off"
              spellCheck="false"
              onChange={e => {
                setQuery(e.target.value);
                searchView();
              }}
            />
          </div>
        </div>
        <hr id="top-hr" className="hide  mt-0 mb-0" />
        {view === "view1" ? (
          <Landing searchView={searchView} />
        ) : (
          <View query={query} />
        )}
      </div>
    </div>
  );
}

export default App;
