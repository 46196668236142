import React, { useState, useEffect } from "react";
import * as dataJson from "../data/data.json";
let data = dataJson.default;

console.log(data);

function View(props) {
  //    let drugNames = data.map(item => item["Proprietary Name"])
  let drugsInSearch = data.filter(
    item =>
      item["Proprietary Name"]
        .toLowerCase()
        .includes(props.query.toLowerCase()) ||
      item["Proper Name"].toLowerCase().includes(props.query.toLowerCase()) ||
      item["Applicant"].toLowerCase().includes(props.query.toLowerCase())
  );

  let originators = data.filter(item => !item["Proper Name"].includes("-"));
  // console.log(originators);

  return (
    <div>
      <div className="row no-gutters mb-4 p-2">
        <div className="col-md-12">
          {/* <p>query: {props.query}</p> */}
          {/* {drugsInSearch.map(item => {
                return <h6>{item["Proprietary Name"]}</h6>;
              })} */}
          {drugsInSearch.map(item => {
            return (
              <div className="bq-tbl-row">
                <div className="bq-tbl-cell">
                  <h6>{item["Applicant"]}</h6>
                </div>
                <div className="bq-tbl-cell">
                  <h6>{item["Proprietary Name"]}</h6>
                </div>
                <div className="bq-tbl-cell">
                  <h6>{item["Proper Name"]}</h6>
                </div>
                <div className="bq-tbl-cell">
                  <h6>{item["BLA Type"]}</h6>
                </div>
                <div className="bq-tbl-cell">
                  <h6>{item["Strength"]}</h6>
                </div>
                <div className="bq-tbl-cell">
                  <h6>{item["Dosage Form"]}</h6>
                </div>
                <div className="bq-tbl-cell">
                  <h6>
                    {item["Route of Administration"] === "Subcutaneous"
                      ? "SQ"
                      : item["Route of Administration"] === "Intravenous"
                      ? "IV"
                      : "SQ or IV"}
                  </h6>
                </div>

                <div className="bq-tbl-cell">
                  <h6>{item["Product Presentation"]}</h6>
                </div>
                {/* <div className="bq-tbl-cell">
                  <h6>{item["Mkt. Status"]}</h6>
                </div> */}
                {/* <div className="bq-tbl-cell">
                  <h6>{item["Product License Status"]}</h6>
                </div> */}
                <div className="bq-tbl-cell">
                  <h6>{item["BLA Number"]}</h6>
                </div>
                <div className="bq-tbl-cell">
                  <h6>{item["Approval Date"]}</h6>
                </div>
                {/* <div className="bq-tbl-cell">
                  <h6>{item["Ref. Product Proper Name"]}</h6>
                </div> */}
                {/* <div className="bq-tbl-cell">
                  <h6>{item["Supplement Number"]}</h6>
                </div>
                <div className="bq-tbl-cell">
                  <h6>{item["Submission Type"]}</h6>
                </div>
                <div className="bq-tbl-cell">
                  <h6>{item["License Number"]}</h6>
                </div>
                <div className="bq-tbl-cell">
                  <h6>{item["Product Number"]}</h6>
                </div> */}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default View;
